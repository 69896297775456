

























































































import { Component, Mixins } from 'vue-property-decorator';
import { Profile, ListingStatus, WebsiteLevel } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import { ListingFilter, ListingOrderBy, TestimonialFilter, TestimonialOrderBy } from 'client-website-ts-library/filters';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';

import Masthead from '@/components/Common/Masthead.vue';
import Listings from '@/components/Listings.vue';
import TestimonialList from '@/components/Testimonials/TestimonialList.vue';
import Loader from '@/components/UI/Loader.vue';

import { AnalyticsEventObject } from 'client-website-ts-library/services/Analytics';

@Component({
  components: {
    Masthead,
    Listings,
    TestimonialList,
    Loader,
  },
})
export default class AgentProfile extends Mixins(AsyncView) {
  private profile: Profile | null = null;

  private currentListingFilter: ListingFilter | null = null;

  private soldListingFilter: ListingFilter | null = null;

  private testimonialFilter: TestimonialFilter | null = null;

  private hasSoldListings = true;

  private hasCurrentListings = true;

  private hasTestimonials = true;

  resolveId() {
    const { id } = this.$route.params;

    if (id.length === 36) {
      // If the ID is 36 long we'll treat it as a guid
      this.updateAgent(id);
    } else {
      API.Slugs.Resolve(id).then((resolved) => {
        if (resolved.Type === 'Profile') {
          this.updateAgent(resolved.Id);
        }
      });
    }
  }

  stripHtml(html: string): string {
    const el = document.createElement('div');

    el.innerHTML = html;

    return el.innerText;
  }

  head(): RouteMeta | null {
    if (this.profile === null) return null;

    let about = this.stripHtml(this.profile.AboutAgent);

    if (about.length > 156) {
      about = `${about.substring(0, 156)}...`;
    }

    return {
      title: `${this.profile.FullName} - ${this.profile.Position}`,
      description: about,
      image: this.profile.Photo ? this.profile.Photo.Preview.Url : undefined,
    };
  }

  updateAgent(id: string) {
    API.Profiles.Get(id).then((profile) => {
      this.profile = profile;

      this.$emit('updateView');

      this.currentListingFilter = new ListingFilter({
        SearchLevel: WebsiteLevel.Profile,
        SearchGuid: this.profile!.Id,
        Statuses: [
          ListingStatus.Current,
          ListingStatus.UnderContract,
        ],
        OrderByStatements: [
          ListingOrderBy.CreateDateDesc,
        ],
        PageSize: 6,
      });

      this.soldListingFilter = new ListingFilter({
        SearchLevel: WebsiteLevel.Profile,
        SearchGuid: this.profile!.Id,
        Statuses: [
          ListingStatus.Sold,
          ListingStatus.Leased,
        ],
        OrderByStatements: [
          ListingOrderBy.LastUpdatedDateDesc,
        ],
        PageSize: 6,
      });

      this.testimonialFilter = new TestimonialFilter({
        SearchLevel: WebsiteLevel.Profile,
        SearchGuid: this.profile!.Id,
        OrderByStatements: [
          TestimonialOrderBy.CreateDateDesc,
        ],
      });
    });
  }

  mounted() {
    this.resolveId();
  }

  getAnalyticsData(): AnalyticsEventObject | null {
    if (this.profile === null) return null;

    return AnalyticsEventObject.FromProfile(this.profile);
  }
}
